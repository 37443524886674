// https://github.com/gatsbyjs/gatsby/issues/7921
const transitionDelay = 250

exports.shouldUpdateScroll = ({ pathname }) => {
    if (window.__goForward) {
        window.setTimeout(() => window.scrollTo(0, 0), 250)
    } else {
        document.querySelector('body').style.opacity = 0
        window.setTimeout(() => {
            document.querySelector('body').style.opacity = 1
        }, 250)
    }
    /*
    console.log(window.__goForward)
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
    if (window.__goBack) {
    } else {
        //window.setTimeout(() => window.scrollTo(0, 0), 0)
        //return true
    }
    /*
    if (window.__goForward) {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
    } else {
        return true
    }
    /*
    // // We use a temporary hack here, see #7758
    if (window.__navigatingToLink) {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
    } else {
        const savedPosition = JSON.parse(
            window.sessionStorage.getItem(`@@scroll|${pathname}`)
        )
        window.setTimeout(
            () => window.scrollTo(...savedPosition),
            transitionDelay
        )
    }
    */
    window.__goForward = false
    return false
}
