/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withPrefix, useStaticQuery, graphql } from 'gatsby'

const SEO = ({ description, lang, meta, title, showMenu }) => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        social {
                            twitter
                        }
                    }
                }
            }
        `
    )

    const metaDescription = description || site.siteMetadata.description
    const defaultTitle = site.siteMetadata?.title
    console.log({ description })
    return (
        <Helmet
            htmlAttributes={{
                lang,
                class: showMenu ? 'show-menu' : null
            }}
            className="application"
            title={title}
            titleTemplate={
                defaultTitle != title
                    ? `%s | ${defaultTitle}`
                    : `We help businesses thrive in the digital world | ${defaultTitle}`
            }
            meta={[
                {
                    name: `description`,
                    content: metaDescription
                },
                {
                    property: `og:title`,
                    content: title
                },
                {
                    property: `og:description`,
                    content: metaDescription
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                {
                    name: `twitter:card`,
                    content: `summary`
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata?.social?.twitter || ``
                },
                {
                    name: `twitter:title`,
                    content: title
                },
                {
                    name: `twitter:description`,
                    content: metaDescription
                },
                {
                    itemprop: 'image',
                    content: '/geltonas.jpg'
                },
                {
                    property: 'og:image',
                    content: '/geltonas.jpg'
                }
            ].concat(meta)}
        >
            <link rel="preconnect" href="https://cdn.jsdelivr.net" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            {/*
            <link
                rel="stylesheet"
                href="https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/plus-jakarta-display.min.css"
            />
            <link
                href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap"
                rel="stylesheet"
            />
            <script
            src={withPrefix('../../js/plugins.min.js')}
            type="text/javascript"
            />
            */}
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: `We believe that any successful project should start with a successful conversation. So we always begin by figuring out what makes you and your goals unique. As a team of seasoned professionals, we may have hundreds of completed projects in our portfolio, but well never assume yours is just business as usual.`
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired
}

export default SEO
