import React, { useState } from 'react'

import Header from '../components/header'
import SEO from '../components/seo'
import Transition from '../components/transition'

const Layout = ({ location, title, description, children, darkerHeader }) => {
    const [showMenu, setShowMenu] = useState(false)
    const useTitle = title || `Unlike Usual`
    return (
        <div>
            <SEO
                title={useTitle}
                description={description}
                showMenu={showMenu}
            />
            <Header
                title={title}
                darkerHeader={darkerHeader}
                location={location}
                setShowMenu={setShowMenu}
            />
            <Transition location={location}>{children}</Transition>
        </div>
    )
}

export default Layout
