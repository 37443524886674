import React from 'react'

import Svg from './svg'
import Link from './link'

const Header = ({ title, location, setShowMenu, darkerHeader }) => {
    const rootPath = `${__PATH_PREFIX__}/`
    const isRootPath = location && location.pathname === rootPath
    const darker = false
    //const darker =
    //    isRootPath ||
    //    (location && location.pathname === '/blog') ||
    //    darkerHeader

    return (
        <header className={darker ? 'header header-darker' : 'header'}>
            <Link className="logo" aria-label="Unlike Usual" to="/">
                <Svg name="logo" />
            </Link>
            <ul>
                <li>
                    <Link className={isRootPath ? 'active' : null} to="/">
                        Home
                    </Link>
                </li>
                <li>
                    <Link
                        className={title == 'About' ? 'active' : null}
                        to="/about"
                    >
                        About
                    </Link>
                </li>
                <li>
                    <Link
                        className={title == 'Approach' ? 'active' : null}
                        to="/approach"
                    >
                        Approach
                    </Link>
                </li>
                {/*
                <li>
                    <Link
                        className={title == 'Work' ? 'active' : null}
                        to="/work"
                    >
                        Work
                    </Link>
                </li>
                <li>
                    <Link
                        className={title == 'Blog' ? 'active' : null}
                        to="/blog"
                    >
                        Blog
                    </Link>
                </li>
                */}
                <li>
                    <Link
                        className={title == 'Contact' ? 'active' : null}
                        to="/contact"
                    >
                        Contact
                    </Link>
                </li>
            </ul>
            <div
                className="menu menu-open desktop-hide"
                onClick={() => setShowMenu(1)}
            >
                <Svg name="menu" />
            </div>
            <div
                className="menu desktop-hide menu-close"
                onClick={() => setShowMenu(0)}
            >
                <Svg name="close" />
            </div>
        </header>
    )
}

export default Header
