import React from 'react'
import { Link } from 'gatsby'

export default ({ children, ...props }) => {
    const onClick = () => {
        if (typeof window != 'undefined') {
            window.__goForward = true
        }
    }

    return (
        <Link {...props} onClick={onClick}>
            {children}
        </Link>
    )
}
