import React from 'react'

export default ({ name, className }) => {
    const version = 1
    const url = `/img/sprite.svg?ver=${version}#${name}`
    return (
        <svg className={className}>
            <use xlinkHref={url} href={url}></use>
        </svg>
    )
}
